<script>
import { find } from 'lodash'

export default {
  methods: {
    dictionary (dictionary) {
      return window.Laravel.dictionaries[dictionary]
    },
    dictionaryLabel (dictionary, key) {
      let item = find(this.dictionary(dictionary), el => el.value === key)
      return item && item.label
    },
    nameFromDictionary (dict, value) {
      let node = find(this.dictionary(dict), el => el.value === value)
      return node ? node.label : 'Unknown'
    }
  }
}
</script>
